<template>
  <div v-loading="pageloading">
    <configuration  @save = "save('ruForm')" img="/image/20221114-1e2ba690-b5ed-4303-81f8-e15084e14501.png">
      <el-form :model="ruForm" :rules="rules" ref="ruForm" label-width="150px">
        <div>
          <div class = "titlefont">入口配置</div>
          <div class= "grayline"></div>
          <el-form-item prop="QyWeixinContactMeId" label="选择员工活码：">
            <el-select v-model="ruForm.QyWeixinContactMeId" placeholder="选择员工活码" popper-class = "seloption-pad" :popper-append-to-body="false"
            style="width:350px" :loading = "selloading" ref="select" filterable>
              <div class = "empty-topRight" v-if="QyWeixinContactMeIdlist&&QyWeixinContactMeIdlist.length">
                  <el-button type="text" class = "topRight-button" @click="jumplist">添加员工活码</el-button>
              </div>
              <el-option v-for="(v,i) in QyWeixinContactMeIdlist" :key="v.Id" :value="v.Id" :label="v.Remark"></el-option>
              <div slot="empty">
                <div class = "nolist">
                  查询不到相关员工活码数据，<el-button type="text" @click="jumplist">点击前往创建</el-button>
                </div>
              </div>
            </el-select>
            <div class = "smallfont" style="margin:5px 0px">客户商品购买成功后，将在支付成功页展示添加员工企微好友的入口</div>
          </el-form-item>
          <el-form-item prop="EntranceStyleType" label="入口样式配置：">
            <el-radio-group v-model="ruForm.EntranceStyleType">
              <el-radio :label="1">系统默认样式</el-radio>
              <el-radio :label="2">自定义上传入口图片</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="90px" v-if="ruForm.EntranceStyleType==1">
            <el-form-item label="标签文案：">
              <el-input v-model="ruForm.LabelText" placeholder="加好友" style="width:300px" maxlength="4"></el-input>
            </el-form-item>
            <el-form-item label="加好友引导描述：" style="margin:20px 0px">
              <el-input v-model="ruForm.AddFriendGuideDescription" placeholder="添加企微好友，获得更多专属权益" style="width:300px" maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="按钮文案：">
              <el-input v-model="ruForm.ButtonText" placeholder="立即添加" style="width:300px" maxlength="4"></el-input>
            </el-form-item>
          </el-form-item>
          <el-form-item v-if="ruForm.EntranceStyleType==2" prop="StyleImgUrl" label-width="150px" :rules="rules.StyleImgUrl" :key="'StyleImgUrl'">
            <el-upload class="avatar-uploader uploadClass" :action="imgApi" :before-upload="beforeUpload"
						:on-success="handleUploadSuccess" accept="image/*"
						:show-file-list="false">
              <img v-if="ruForm.StyleImgUrl" :src="imgUrl + ruForm.StyleImgUrl" class="avatar uploadClass">
              <i v-else class="el-icon-plus avatar-uploader-icon" style="font-size:20px"></i>
            </el-upload>
            <div class = "smallfont" style="margin:5px 0px">图片建议宽高比2:1，图片大小请控制在1M以内</div>
          </el-form-item>
        </div>
      </el-form>
    </configuration>
  </div>
</template>

<script>
import configuration from "./components/configuration"
import config from "@/config"
import{
  qyWeixinContactMeListRequest,
  qyWeixinIncreaseFansedit,
  qyWeixinIncreaseFanslist
}from "@/api/sv1.0.0"
export default {
  components:{
    configuration,
  },
  data () {
    return {
      pageloading:false,
      imgApi: config.UPLOAD_IMG,
			imgUrl: config.IMG_BASE,
      ruForm:{
        Id:0,
        PageType:2,
        QyWeixinContactMeId:'',
        EntranceStyleType:1,
        LabelText:'',
        AddFriendGuideDescription:'',
        ButtonText:'',
        StyleImgUrl:'',
      },
      rules:{
        QyWeixinContactMeId:[
          { required: true, message: '请从已保存的员工活码中选择一个', trigger: 'blur' },
        ],
        EntranceStyleType:[
          { required: true, message: '选择入口样式配置', trigger: 'blur' },
        ],
        StyleImgUrl:[
          { required: true, message: '请上传入口图片', trigger: 'blur' },
        ],
      },
      selloading:false,
      QyWeixinContactMeIdlist:[],
    }
  },
  created () {
    this.getqyWeixinContactMeListRequest()
    this.getrisefanslist()
  },
  methods: {
    jumpcreated(){
      this.$router.push({
        path:'/qyCustomer/callmeedit?Id=0'
      })
    },
    jumplist(){
      this.$router.push({
        path:'/qyCustomer/callme'
      })
    },
    beforeUpload(file){
      const isLt1M = file.size / 1024 / 1024 < 1;
				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!isLt1M) {
					this.$message.error('图片大小请控制在1M以内')
					return false
				}
			return true;
    },
    handleUploadSuccess(file){
      this.ruForm.StyleImgUrl = file[0]
    }, 
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.tosave()
        } else {
          this.$message.error('请完善配置')
          this.$nextTick(()=>{
              let iserror = document.getElementsByClassName('is-error')
              iserror[0].scrollIntoView({
                block:'center',
                behavior:'smooth'
              })
          })
          return false;
        }
      });
    },
    async tosave(){
      this.pageloading = true
      try{
        let data = JSON.parse(JSON.stringify(this.ruForm))
        if(data.EntranceStyleType==1){
          data.StyleImgUrl=''
          if(!data.LabelText){
            data.LabelText='加好友'
          }
          if(!data.AddFriendGuideDescription){
            data.AddFriendGuideDescription='添加企微好友，获得更多专属权益'
          }
          if(!data.ButtonText){
            data.ButtonText='立即添加'
          }
        }else if(data.EntranceStyleType==2){
          data.LabelText=''
          data.AddFriendGuideDescription='',
          data.ButtonText=''
        }
        let newdata = {
          QyWeixinIncreaseFans:data
        }
        let result = await qyWeixinIncreaseFansedit(newdata)
        if(result.IsSuccess){
          this.$message.success('保存成功')
          this.$router.go(-1)
        }
      }finally{
        this.pageloading = false
      }
    },
    async getrisefanslist(){
      this.pageloading = true
      try{
        let res = await qyWeixinIncreaseFanslist()
        if(res.IsSuccess){
          let message = res.Result.find((v)=>{
            return v.PageType == 2
          })
          if(message){
            let msg =  this.QyWeixinContactMeIdlist.some((v)=>{
              return v.Id==message.QyWeixinContactMeId
            })
            if(!msg){
              message.QyWeixinContactMeId = ''
            }
            this.ruForm = message
          }

          // console.log(this.message)
        }
      }finally{
        this.pageloading = false
      }
    },
    async getqyWeixinContactMeListRequest(){
      this.selloading = true
      try{
        let data = {
          Skip:0,
          Take:99999,
          OrderBy:'AddTime',
        }
        let result = await qyWeixinContactMeListRequest(data)
        if(result.IsSuccess){
          this.QyWeixinContactMeIdlist = result.Result.Results
        }
        this.getrisefanslist()
      }finally{
        this.selloading = false
      }
    },
  }
}
</script>

<style lang = "less" scoped>
.titlefont{
  font-size: 15px;
  font-weight: bold;
}
.smallfont{
  font-size: 12px;
  color: #999999;
  line-height: 1.3;
}
.grayline{
  width:100%;
  height: 1px;
  background: #d5d5d5;
  transform: scale(1,.5);
  margin:20px 0px
}
.empty-topRight{
  width:100%;
    /* border:1px solid black; */
    text-align:right;
    margin-bottom:5px;
    .topRight-button{
      margin:0px;
      padding:0px;
      margin-right:10px
    }
}
.empty{
  padding:10px 0px;
  /* border:1px solid red; */
  max-width: 350px;
  .empty-topRight{
    width:100%;
    /* border:1px solid black; */
    text-align:right;
    margin-bottom:5px;
    .topRight-button{
      margin:0px;
      padding:0px;
      margin-right:10px

    }
  }
  .empty-optionBox{
    /* border:1px solid black; */
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    .empty-option-font{
      flex:1;
      font-size:14px;
      line-height: 1.5;
      padding: 5px 15px;
      width: 100%;
      font-weight: 100;
    }
    .selectedfont{
      color:#409eff;
      font-weight: bold;
    }
  }
  .empty-optionBox:hover{
    background: #F5F7FA;
  }
}
.nolist{
  padding: 10px;
  text-align: center;
  font-size: 12px;
  color: #909299;
}
.maxoption{
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
}
.fonthidden{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.uploadClass{
  width: 400px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border:1px dashed #DCDFE6;
  border-radius: 3px;
}

.uploadClass ::v-deep .el-upload{
  display:block !important
}
</style>
<style>
.seloption-pad ::v-deep .el-select-dropdown__list{
    padding:10px 0px !important
  }
</style>